import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { lazy, StrictMode, Suspense, useEffect, useState } from 'react';

import { dynamicActivate } from '@/locale/i18n';

import styles from './app.css?inline';
import { AppSettings } from './constants/AppSettings';
import { ChatConfigProvider } from './contexts/ChatConfigProvider';
import { defaultQueryClient } from './services/queryClient';
import { Widget } from './Widget';

const ReactQueryDevtools = lazy(() =>
  import('@tanstack/react-query-devtools').then(res => ({
    default: res.ReactQueryDevtools,
  })),
);

type AppProps = {
  chat: string | null | undefined;
};

export function App({ chat }: AppProps) {
  const [languageLoaded, setLanguageLoaded] = useState(false);

  // Load translations
  useEffect(() => {
    if (languageLoaded) {
      return;
    }

    dynamicActivate().then(() => {
      setLanguageLoaded(true);
    });
  }, [languageLoaded]);

  if (!chat) {
    console.error('Missing chatId');

    return null;
  }

  // Wait for translations to load before rendering
  if (!languageLoaded) {
    return null;
  }

  return (
    <StrictMode>
      {/* .kanbu-chatbot-app is used for tailwind scoping */}
      <div
        // eslint-disable-next-line tailwindcss/no-custom-classname
        className='kanbu-chatbot-app'
        data-kanbu-chat={chat}
        data-kanbu-version={AppSettings.version}
      >
        <div className='font-sans text-base text-foreground'>
          <div
            id='kanbu-chatbot-app__container'
            className='relative z-[1000000]'
          />
          <I18nProvider i18n={i18n}>
            <QueryClientProvider client={defaultQueryClient}>
              <ChatConfigProvider chatId={chat}>
                <div className='relative z-[999999]'>
                  {/* Handles style injection to shadow dom in Web Component */}
                  <style dangerouslySetInnerHTML={{ __html: styles }} />

                  {/* Chatbot widget */}
                  <Widget />
                </div>
                {AppSettings.debug && (
                  <Suspense fallback={null}>
                    <ReactQueryDevtools buttonPosition='bottom-left' />
                  </Suspense>
                )}
              </ChatConfigProvider>
            </QueryClientProvider>
          </I18nProvider>
        </div>
      </div>
    </StrictMode>
  );
}
