import {
  ToolNames,
  type UsageLimitsErrorToolArgs,
} from '@kanbu/schema/contracts';
import { ChatRole, ThreadMode, ToolType } from '@kanbu/schema/enums';
import { ErrorCodes } from '@kanbu/shared';
import { ChatRendererProvider, createMessage } from '@kanbu/shared-ui';
import { useLingui } from '@lingui/react/macro';
import { useQueryClient } from '@tanstack/react-query';
import { cn } from '@utima/ui';
import { AnimatePresence } from 'framer-motion';
import { useCallback, useEffect, useState, type FormEvent } from 'react';
import { v7 as uuidV7 } from 'uuid';

import type { UseChatParams } from '@/components/chat/chatTypes';
import { useChatThreads } from '@/components/chat/useChatThreads';
import { useChatConfig } from '@/contexts/ChatConfigProvider';
import { threadKeys } from '@/services/queryClient';
import { useBoundStore } from '@/store/store';

import { ChatbotChatRenderer } from './ChatbotChatRenderer';
import { ThreadModeIndicator } from './ThreadModeIndicator';
import { useActivityPing } from './useActivityPing';
import { useChat } from './useChat';
import { InputBox } from '../inputBox/InputBox';
import { TakeoverNotify } from '../takeover/TakeoverNotify';

export function Chat() {
  const { t } = useLingui();
  const { chatId, chatbotConfig } = useChatConfig();
  const [faqIndex, setFaqIndex] = useState<number | null>(null);
  const [messageCounter, setMessageCounter] = useState(0);
  const [
    threadId,
    setThreadId,
    opened,
    setUnread,
    childrenCount,
    isInFAQLoop,
    currentFAQId,
    setResetConversation,
  ] = useBoundStore(state => [
    state.threadId,
    state.setThreadId,
    state.opened,
    state.setUnread,
    state.childrenCount,
    state.isInFAQLoop,
    state.currentFAQId,
    state.setResetConversation,
  ]);

  const { handleThreadRefresh, threads, activeThread } = useChatThreads({
    chatId,
    threadId,
    setThreadId,
    opened,
  });

  const queryClient = useQueryClient();

  /**
   * Activity ping to keep track of user activity, current
   * conversation mode and handle admin takeover.
   */
  const { ping, pingData, acceptTakeover, denyTakeover } =
    useActivityPing(threadId);

  /**
   * Always set unread, this should be cleaned up when clicking close button.
   * Set unread state and refresh threads when receiving a message
   */
  const handleMessage = useCallback(() => {
    setUnread(1);

    if (threads) {
      handleThreadRefresh();
    }
  }, [setUnread, threads, handleThreadRefresh]);

  /**
   * Error handler for the chat.
   */
  const handleError = useCallback<Required<UseChatParams>['onError']>(
    async (error, insert) => {
      /**
       * Handle API errors
       */
      console.error(error);
      if (typeof error === 'object' && error !== null && 'errorCode' in error) {
        const { errorCode, data = {} } = error as {
          errorCode: string;
          data?: Record<string, unknown>;
        };

        // Use special tool for the usage limits
        if (errorCode === ErrorCodes.USAGE_LIMITS_EXCEEDED) {
          return insert({
            id: uuidV7(),
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
            role: ChatRole.Assistant,
            toolCalls: [
              {
                name: ToolNames.UsageLimitsError,
                args: data satisfies UsageLimitsErrorToolArgs,
                type: ToolType.Tool,
                index: 0,
                id: 'usage-limits-error',
              },
            ],
          });
        }

        if (errorCode === ErrorCodes.AGENT_DOES_NOT_EXIST) {
          return insert(
            t`We are sorry, but the agent does not exist. Please try again later.`,
            ChatRole.Status,
          );
        }
      }

      /**
       * Handle message errors
       */
      if (typeof error === 'string') {
        return insert(
          createMessage({
            message: error,
            role: ChatRole.Status,
          }),
        );
      }

      /**
       * Handle all other errors
       */
      return insert(
        createMessage({
          message: t`An error occurred, the chat is currently unavailable. Please try again later.`,
          role: ChatRole.Status,
        }),
      );
    },
    [t],
  );

  /**
   * This handles the chat logic, it fetches the messages from the API
   * and sends new messages to the API.
   */
  const {
    input,
    handleInputChange,
    messages,
    handleSubmit,
    isLoading,
    isFetching,
    abort,
    insert,
    append,
  } = useChat({
    threadId,
    chatId,
    activateLiveChat: activeThread?.mode === ThreadMode.Human,
    initialMessages: [
      createMessage({
        message:
          chatbotConfig?.initialMessage ||
          t`Hi, I am generative AI chatbot Kanbu, which can help with technical support for customers and knowledge base for employees. I can work with data from the web, database, and documents, and based on this, I can answer immediately, correctly, and authentically! How can I help you?`,
        role: ChatRole.Assistant,
      }),
    ],
    onError: handleError,
    onMessage: handleMessage,
  });

  /**
   * Following two useEffects are used to reset the faqIndex when the threadId changes
   * or when the messages change. This is used to always make sure we show the FAQs
   * after the last AI message upon conversation reset or page reload.
   */
  useEffect(() => {
    setFaqIndex(null);
  }, [threadId]);

  const wrapHandleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      // Update the message counter only for AI mode
      if (activeThread?.mode === ThreadMode.AI) {
        setMessageCounter(value => value + 1);
      }

      return handleSubmit(e);
    },
    [activeThread?.mode, handleSubmit],
  );

  useEffect(() => {
    if (messages && messages.length > 0) {
      const lastAiMessageIndex = messages.findLastIndex(
        msg => msg.role === ChatRole.Assistant,
      );

      if (
        faqIndex === null ||
        (childrenCount && childrenCount > 0 && isInFAQLoop) ||
        (currentFAQId && currentFAQId !== '')
      ) {
        setFaqIndex(lastAiMessageIndex === -1 ? 0 : lastAiMessageIndex);
      }
    }
  }, [messages, faqIndex, childrenCount, isInFAQLoop, currentFAQId]);

  // Handle reset tooltip visibility
  useEffect(() => {
    if (messageCounter === 2) {
      setResetConversation(true);
    }
  }, [messageCounter, setResetConversation]);

  /**
   * Threads are empty when the user visits that chat for the first time.
   * First message is welcome message, so we have to fetch the threads
   * after the first user message is sent.
   */
  useEffect(() => {
    if (
      !activeThread &&
      !isFetching &&
      !isLoading &&
      messages.filter(m => m.role === ChatRole.User).length
    ) {
      queryClient.invalidateQueries({
        queryKey: threadKeys.chatLists(chatId),
      });
    }
  }, [messages, isFetching, isLoading, queryClient, chatId, activeThread]);

  return (
    <>
      <AnimatePresence mode='wait'>
        {pingData?.takeover && threadId && (
          <TakeoverNotify
            takeover={pingData.takeover}
            onAccept={acceptTakeover}
            onDeny={denyTakeover}
          />
        )}
      </AnimatePresence>
      <form
        className='relative flex h-full flex-col'
        onSubmit={wrapHandleSubmit}
      >
        <ThreadModeIndicator
          onSwitchToAI={() => ping.mutate(ThreadMode.AI)}
          mode={activeThread?.mode}
        />
        <div className='flex grow flex-col-reverse overflow-hidden overflow-y-auto px-5 py-4 will-change-transform'>
          <ChatRendererProvider insert={insert} append={append}>
            <ChatbotChatRenderer
              insert={insert}
              isInFAQLoop={isInFAQLoop}
              faqIndex={faqIndex}
              messages={messages}
              isLoading={isLoading}
              isFetching={isFetching}
              thread={activeThread}
            />
          </ChatRendererProvider>
        </div>
        <div className={cn('shrink-0 bg-background-secondary px-5 py-4')}>
          <InputBox
            value={input}
            onChange={handleInputChange}
            onAbort={abort}
            isFetching={isFetching}
            isLoading={isLoading}
            maxLength={chatbotConfig?.maxCharacters}
            disclaimerContact={chatbotConfig?.disclaimerContact}
          />
        </div>
      </form>
    </>
  );
}
