import type {
  ChatbotConfig,
  ChatThemeConfig,
  OrganizationWorkingHours,
} from '@kanbu/schema';
import { pascalToKebab } from '@kanbu/shared';
import { useQuery } from '@tanstack/react-query';
import {
  type PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

import { aiCoreApi } from '@/services/aiCoreClient';
import { chatKeys } from '@/services/queryClient';

export interface ChatConfig {
  chatId: string;
  agentName: string;
  chatbotConfig: ChatbotConfig;
  themeConfig: ChatThemeConfig;
  workingHours?: OrganizationWorkingHours;
}

export const ChatConfigContext = createContext<ChatConfig | null>(null);

// Cache key prefix for localStorage
const CACHE_KEY_PREFIX = 'kanbu-chat-config-';

/**
 * For now we disable the caching since customers are stupid and unable
 * to wait for 1 minute for the config to load. We will revisit this
 * in the future, when we have better preview for config changes
 * in the admin app.
 */
const DEFAULT_CACHE_DURATION_MS = 0; // 0 minutes

interface CachedConfig {
  data: ChatConfig;
  expiresAt: number;
}

interface ChatConfigProps extends PropsWithChildren {
  chatId: string;
  cacheDuration?: number;
}

/**
 * We want to disable caching in playground and local development.
 */
const shouldUseCachedConfig = () => {
  return !(
    window.location.hostname.startsWith('playground.') ||
    window.location.hostname.startsWith('localhost')
  );
};

/**
 * Fetches config from the server and provides it to the app.
 * Returns no children until the config is loaded.
 */
export function ChatConfigProvider({
  chatId,
  children,
  cacheDuration = DEFAULT_CACHE_DURATION_MS,
}: ChatConfigProps) {
  const cacheKey = `${CACHE_KEY_PREFIX}${chatId}`;
  const cacheEnabled = shouldUseCachedConfig();

  // Lazy initialization of config from localStorage
  const [config, setConfig] = useState<ChatConfig | null>(() => {
    if (!cacheEnabled) {
      return null;
    }

    try {
      const cachedData = localStorage.getItem(cacheKey);

      if (cachedData) {
        const parsedCache = JSON.parse(cachedData) as CachedConfig;

        // Check if cache is still valid
        if (parsedCache.expiresAt > Date.now()) {
          return parsedCache.data;
        } else {
          // Clear expired cache
          localStorage.removeItem(cacheKey);
        }
      }
    } catch (error) {
      console.error('Error reading from cache:', error);
      localStorage.removeItem(cacheKey);
    }

    return null;
  });

  const { data } = useQuery({
    queryKey: chatKeys.config(chatId),
    queryFn: () => aiCoreApi.chat.config({ chatId }),
    enabled: !!chatId && !config, // Only fetch if no cached config is available
    staleTime: Number.POSITIVE_INFINITY,
  });

  /**
   * Create chat config state when the data are loaded.
   */
  useEffect(() => {
    if (data) {
      const newConfig: ChatConfig = {
        chatId,
        agentName: data.agentName,
        chatbotConfig: data.chatbotConfig,
        themeConfig: data.themeConfig ?? {},
        workingHours: data.workingHours,
      };

      // Cache the config in localStorage if caching is enabled
      if (cacheEnabled) {
        try {
          const cacheData: CachedConfig = {
            data: newConfig,
            expiresAt: Date.now() + cacheDuration,
          };
          localStorage.setItem(cacheKey, JSON.stringify(cacheData));
        } catch (error) {
          console.error('Error caching config:', error);
        }
      }

      setConfig(newConfig);
    }
  }, [chatId, data, cacheDuration, cacheKey, cacheEnabled]);

  /**
   * Set CSS variables from config
   */
  useEffect(() => {
    if (!config) {
      return;
    }

    /**
     * Find chat root element, web component selector is
     * used in production, fallback to data attribute in dev mode.
     */
    const chatRoot =
      document.querySelector<HTMLDivElement>(
        `kanbu-chatbot[chat="${config.chatId}"]`,
      ) ??
      document.querySelector<HTMLDivElement>(
        `[data-kanbu-chat="${config.chatId}"]`,
      );

    // Set CSS variable overrides from config
    if (chatRoot) {
      Object.entries(config.themeConfig).forEach(([key, value]) => {
        if (value) {
          chatRoot.style.setProperty(
            `--kanbu-${pascalToKebab(key)}`,
            value.toString(),
          );
        }
      });
    }
  }, [config]);

  // Don't render anything until the config is loaded
  if (!config) {
    return null;
  }

  return (
    <ChatConfigContext.Provider value={config}>
      {children}
    </ChatConfigContext.Provider>
  );
}

/**
 * Hook for accessing chat config.
 */
export function useChatConfig() {
  const context = useContext(ChatConfigContext);

  if (!context) {
    throw new Error('Missing ChatConfigContext.Provider in the tree');
  }

  return context;
}
