export type EventHandler<T extends (...args: any[]) => void> = T;

/**
 * Generic event emitter class, cen be used as a base class
 * for any class that needs to emit events.
 */
export abstract class Emitter<
  Events extends Record<string, EventHandler<any>>,
> {
  protected handlers: Map<keyof Events, Array<Events[keyof Events]>> =
    new Map();

  /**
   * Register an event handler
   */
  on<K extends keyof Events>(event: K, handler: Events[K]): this {
    this.handlers.set(event, [...(this.handlers.get(event) ?? []), handler]);

    return this;
  }

  /**
   * Remove an event handler
   */
  off<K extends keyof Events>(event: K, handler: Events[K]): this {
    this.handlers.set(
      event,
      (this.handlers.get(event) ?? []).filter(h => h !== handler),
    );

    return this;
  }

  /**
   * Emit a custom event
   */
  emit<K extends keyof Events>(event: K, ...args: Parameters<Events[K]>): void {
    const handlers = this.handlers.get(event) ?? [];

    for (const handler of handlers) {
      // Cast handler to any to avoid spread argument type error
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (handler as any)(...args);
    }
  }

  /**
   * Remove all event handlers
   */
  removeAllHandlers(): void {
    this.handlers.clear();
  }
}
