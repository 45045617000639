import { Trans } from '@lingui/react/macro';
import { useQueryClient } from '@tanstack/react-query';
import { MessageSquarePlus } from 'lucide-react';
import { memo, useEffect, useState } from 'react';

import { useChatConfig } from '@/contexts/ChatConfigProvider';
import { aiCoreApi } from '@/services/aiCoreClient';
import { chatKeys, threadKeys } from '@/services/queryClient';
import { useBoundStore } from '@/store/store';

import { TopBarButton } from './TopBarButton.js';
import { SimpleTooltip } from '../tooltip/SimpleTooltip.js';

type NewThreadProps = {
  disabled?: boolean;
  onReset?: () => void;
};

export const NewThread = memo(
  ({ disabled = false, onReset }: NewThreadProps) => {
    const { chatId } = useChatConfig();
    const [isLoading, setIsLoading] = useState(false);
    const { setThreadId, resetFAQLoop } = useBoundStore(state => ({
      setThreadId: state.setThreadId,
      resetFAQLoop: state.resetFAQLoop,
    }));
    const { pushRoute } = useBoundStore(state => ({
      pushRoute: state.pushRoute,
    }));

    const [resetConversation, setResetConversation] = useBoundStore(state => [
      state.resetConversation,
      state.setResetConversation,
    ]);

    useEffect(() => {
      if (resetConversation) {
        const timer = setTimeout(() => {
          setResetConversation(false);
        }, 10000);

        return () => clearTimeout(timer);
      }
    }, [resetConversation, setResetConversation]);

    /**
     * Create new thread to reset the conversation.
     */
    const queryClient = useQueryClient();

    /**
     * Reset conversation by creating new one and invalidating
     * the chat query storage.
     */
    const handleReset = async () => {
      onReset?.();
      setIsLoading(true);

      try {
        // Push route to chat
        pushRoute('chat');

        // Create new thread id
        // FIXME: this will create a thread wout title
        const thread = await aiCoreApi.threads.create({
          chatId,
        });

        // Set thread id
        setThreadId(thread.id);

        // Reset FAQLoop
        resetFAQLoop();

        // Invalidate chat query storage.
        queryClient.invalidateQueries({
          queryKey: chatKeys.detail(chatId),
        });

        // Invalidate thread query storage.
        queryClient.invalidateQueries({
          queryKey: threadKeys.chatLists(chatId),
        });
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    return resetConversation ? (
      <SimpleTooltip
        title={
          <Trans>
            Is this a new topic or are you not satisfied with the answer? Click
            on this button and try again.
          </Trans>
        }
        className='max-w-56'
        open={resetConversation}
        asChild
      >
        <TopBarButton onClick={handleReset} disabled={disabled || isLoading}>
          <MessageSquarePlus />
        </TopBarButton>
      </SimpleTooltip>
    ) : (
      <SimpleTooltip title={<Trans>Start new conversation</Trans>} asChild>
        <TopBarButton onClick={handleReset} disabled={disabled || isLoading}>
          <MessageSquarePlus />
        </TopBarButton>
      </SimpleTooltip>
    );
  },
);
