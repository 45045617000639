import type { ChatMessageType, ThreadType } from '@kanbu/schema';
import { ToolNames } from '@kanbu/schema/contracts';
import { ChatRole, ThreadMode } from '@kanbu/schema/enums';
import {
  createToolRegistry,
  createToolComponent,
  OperatorResultTool,
  RagResultTool,
  ChatRenderer,
  type ToolComponentProps,
  WorkingHoursContext,
  GetShopProductsTool,
  type MessagesGroup,
  GetShopProductTool,
  GetShopProductAccessoriesTool,
  GetShopCategoriesTool,
  GetOperatorTool,
  UsageLimitsErrorTool,
} from '@kanbu/shared-ui';
import { useQueryClient } from '@tanstack/react-query';
import { motion } from 'framer-motion';
import { memo, useCallback, useMemo } from 'react';

import avatar from '@/assets/images/avatar.webp';
import kanbuAvatar from '@/assets/images/bubble-kanbu.webp';
import type { UseChatReturn } from '@/components/chat/chatTypes';
import { useChatConfig } from '@/contexts/ChatConfigProvider';
import { aiCoreApi } from '@/services/aiCoreClient';
import { threadKeys } from '@/services/queryClient';

import { FAQs } from '../faqs/FAQs';
import { Feedback } from '../feedback/Feedback';

export interface ChatbotChatRendererProps {
  thread: ThreadType | null;
  messages: ChatMessageType[];
  insert: UseChatReturn['insert'];
  isInFAQLoop: boolean;
  faqIndex: number | null;
  isLoading: boolean;
  isFetching: boolean;
}

export const ChatbotChatRenderer = memo(
  ({
    thread,
    messages,
    insert,
    isInFAQLoop,
    faqIndex,
    isLoading,
    isFetching,
  }: ChatbotChatRendererProps) => {
    const { agentName, chatbotConfig, themeConfig, workingHours } =
      useChatConfig();
    const queryClient = useQueryClient();

    const systemAvatar = chatbotConfig?.systemAvatar;
    const agentAvatar =
      systemAvatar ?? (themeConfig?.preset === 'kanbu' ? kanbuAvatar : avatar);

    const shouldRenderFeedback = useCallback((group: MessagesGroup) => {
      // Do not render feedback for operator tool calls
      if (group.role === ChatRole.Assistant) {
        return !group.messages.some(message =>
          message.toolCalls?.some(
            toolCall =>
              toolCall.type === 'tool' &&
              toolCall.name === ToolNames.RagResultOperator,
          ),
        );
      }

      return true;
    }, []);

    const toolRegistry = useMemo(
      () =>
        createToolRegistry({
          [ToolNames.GetShopProducts]: GetShopProductsTool,
          [ToolNames.GetShopProduct]: GetShopProductTool,
          [ToolNames.GetShopCategories]: GetShopCategoriesTool,
          [ToolNames.GetShopProductAccessories]: GetShopProductAccessoriesTool,
          [ToolNames.UsageLimitsError]: UsageLimitsErrorTool,
          [ToolNames.GetOperator]: createToolComponent({}, (props: any) => {
            // Get component in PascalCase to satisfy linter
            const OperatorResultComponent = GetOperatorTool.component;

            return (
              <OperatorResultComponent
                {...props}
                mode={thread?.mode ?? ThreadMode.AI}
                onTakeover={async (accepted: boolean) => {
                  if (!thread) {
                    return;
                  }

                  const chatId =
                    typeof thread.chat === 'string'
                      ? thread.chat
                      : thread.chat.id;

                  // Ping the thread to switch to human mode
                  await aiCoreApi.threads.ping({
                    chatId,
                    threadId: thread.id,
                    mode: accepted ? ThreadMode.Human : ThreadMode.AI,
                  });

                  // Invalidate threads to refetch the latest data so the ping status is updated
                  queryClient.invalidateQueries({
                    queryKey: threadKeys.chatLists(chatId),
                  });
                }}
              />
            );
          }),
          [ToolNames.RagResult]: createToolComponent(
            {},
            (props: ToolComponentProps) => {
              // Get component in PascalCase to satisfy linter
              const RagResultComponent = RagResultTool.component;

              return (
                <RagResultComponent
                  {...props}
                  sourcesFormat={chatbotConfig?.sourcesFormat}
                />
              );
            },
          ),
          [ToolNames.RagResultOperator]: createToolComponent(
            {},
            (props: ToolComponentProps) => {
              // Get component in PascalCase to satisfy linter
              const OperatorResultComponent = OperatorResultTool.component;

              return (
                <OperatorResultComponent
                  {...props}
                  mode={thread?.mode ?? ThreadMode.AI}
                  onTakeover={async (accepted: boolean) => {
                    if (!thread) {
                      return;
                    }

                    const chatId =
                      typeof thread.chat === 'string'
                        ? thread.chat
                        : thread.chat.id;

                    // Ping the thread to switch to human mode
                    await aiCoreApi.threads.ping({
                      chatId,
                      threadId: thread.id,
                      mode: accepted ? ThreadMode.Human : ThreadMode.AI,
                    });

                    // Invalidate threads to refetch the latest data so the ping status is updated
                    queryClient.invalidateQueries({
                      queryKey: threadKeys.chatLists(chatId),
                    });
                  }}
                />
              );
            },
          ),
        }),
      [chatbotConfig?.sourcesFormat, queryClient, thread],
    );

    return (
      <WorkingHoursContext.Provider value={workingHours}>
        <ChatRenderer
          messages={messages}
          isFetching={isFetching}
          toolRegistry={toolRegistry}
          shouldRenderFeedback={shouldRenderFeedback}
          agentName={agentName}
          agentAvatar={chatbotConfig?.systemAvatar ?? agentAvatar}
          FeedbackComponent={Feedback}
          renderAfterGroup={(_, index) => {
            if (isInFAQLoop && index === faqIndex && !isLoading) {
              return (
                <motion.div
                  key='faqs'
                  initial={{ y: 10, opacity: 0 }}
                  animate={{ opacity: 1, y: 0 }}
                >
                  <FAQs insert={insert} />
                </motion.div>
              );
            }

            return null;
          }}
        />
      </WorkingHoursContext.Provider>
    );
  },
);
