import { ChatPlacementPosition } from '@kanbu/schema/enums';
import { Image } from '@kanbu/shared-ui';
import { Trans } from '@lingui/react/macro';
import { cn } from '@utima/ui';
import { motion, AnimatePresence } from 'framer-motion';
import { MessageCircle, CircleX } from 'lucide-react';
import { memo, type ComponentProps, useState, useEffect, useMemo } from 'react';

import { useChatConfig } from '@/contexts/ChatConfigProvider';
import { useBoundStore } from '@/store/store';

type BubbleProps = {
  src: string;
  alt?: string;
} & Omit<ComponentProps<'button'>, 'type'>;

const popupVariants = {
  hidden: { opacity: 0, y: 20, scale: 0.8 },
  visible: {
    opacity: 1,
    x: 0,
    y: 0,
    scale: 1,
    transition: {
      type: 'spring',
      stiffness: 500,
      damping: 30,
      delay: 0.2,
    },
  },
  exit: {
    opacity: 0,
    x: 0,
    y: 20,
    scale: 0.8,
    transition: {
      duration: 0.2,
    },
  },
};

export const Bubble = memo(function Bubble({
  src,
  alt = 'Chatbot',
  ...restProps
}: BubbleProps) {
  const [opened, unread, popupClosed, setPopupClosed] = useBoundStore(state => [
    state.opened,
    state.unread,
    state.popupClosed,
    state.setPopupClosed,
  ]);
  const [showPopup, setShowPopup] = useState(false);
  const { chatbotConfig, themeConfig } = useChatConfig();
  const {
    placement,
    bubbleTitle,
    bubbleDescription,
    showPopup: showPopupConfig = true,
    showPopupMobile = false,
  } = chatbotConfig ?? {};

  /**
   * Wait a little bit before showing the popup
   */
  useEffect(() => {
    if (!popupClosed && showPopupConfig) {
      const timer = setTimeout(() => setShowPopup(true), 500);

      return () => clearTimeout(timer);
    }
  }, [popupClosed, showPopupConfig]);

  const renderBubble = useMemo(() => {
    // For mobile screens
    if (typeof window !== 'undefined' && window.innerWidth < 640) {
      return showPopup && showPopupConfig && showPopupMobile;
    }

    // For larger screens
    return showPopup && showPopupConfig;
  }, [showPopup, showPopupConfig, showPopupMobile]);

  const bubbleVariants = useMemo(() => {
    let offsetX = placement?.offsetX ?? 0;
    const offsetY = -(placement?.offsetY ?? 0);

    // If position is left, offsetX should be negative
    if (placement?.position === ChatPlacementPosition.Right) {
      offsetX = -offsetX;
    }

    return {
      hidden: { scale: 0.5, y: 100, opacity: 0 },
      visible: {
        scale: 1,
        x: offsetX,
        y: offsetY,
        opacity: 1,
        transition: {
          type: 'spring',
          stiffness: 400,
          damping: 25,
          duration: 0.5,
        },
      },
      exit: {
        scale: 0.5,
        x: offsetX,
        y: offsetY + 100,
        opacity: 0,
        transition: {
          duration: 0.3,
        },
      },
    };
  }, [placement?.position, placement?.offsetX, placement?.offsetY]);

  const handleClosePopup = () => {
    setPopupClosed(true);
    setShowPopup(false);
  };

  const handleHover = (isHovering: boolean) => {
    if (popupClosed && showPopupConfig) {
      setShowPopup(isHovering);
    }
  };

  return (
    <AnimatePresence>
      {!opened && (
        <motion.div
          className={cn(
            'fixed size-12 sm:size-16 bottom-2 right-2 sm:bottom-6 sm:right-6',
            placement?.position === ChatPlacementPosition.Left
              ? 'left-2 right-auto sm:left-6'
              : 'right-2 left-auto sm:right-6',
          )}
          initial='hidden'
          animate='visible'
          exit='exit'
          variants={bubbleVariants}
          onMouseEnter={() => handleHover(true)}
          onMouseLeave={() => handleHover(false)}
          onClick={() => handleHover(false)}
        >
          <AnimatePresence>
            {renderBubble && (
              <motion.div
                variants={popupVariants}
                initial='hidden'
                animate='visible'
                exit='exit'
                className={cn(
                  'group absolute -top-3 sm:-top-4 z-10 flex w-auto flex-col gap-1 rounded-3xl bg-primary px-4 sm:px-5 py-2 sm:py-3 text-primary-fg shadow-md',
                  placement?.position === ChatPlacementPosition.Left
                    ? 'left-10 sm:left-12 right-auto pl-5 sm:pl-6'
                    : 'right-10 sm:right-12 left-auto pr-6 sm:pr-8',
                )}
              >
                {!popupClosed && (
                  <button
                    className={cn(
                      'absolute -translate-y-1/2 z-10 rounded-xl bg-primary opacity-0 transition-opacity group-hover:opacity-100',
                      placement?.position === ChatPlacementPosition.Left
                        ? '-right-1 sm:-right-2'
                        : '-left-1 sm:-left-2',
                    )}
                    onClick={handleClosePopup}
                  >
                    <CircleX
                      className='size-5 hover:size-[25px] sm:size-6'
                      strokeWidth={1.5}
                    />
                  </button>
                )}
                <p className='whitespace-nowrap text-xs'>
                  {bubbleTitle || <Trans>Hello, I am Kanbu!</Trans>}
                </p>
                <p className='whitespace-nowrap text-xs font-bold sm:text-sm'>
                  {bubbleDescription || <Trans>Ask me anything!</Trans>}
                </p>
              </motion.div>
            )}
          </AnimatePresence>
          <button
            className='group relative z-20 size-12 rounded-full bg-primary shadow-md transition-transform hover:scale-110 active:scale-100 sm:size-16'
            type='button'
            {...restProps}
          >
            <div
              className={cn(
                'flex size-full select-none items-center justify-center rounded-full object-cover overflow-hidden',
              )}
            >
              <Image
                alt={alt}
                src={themeConfig?.logoBubble ?? src}
                className='h-auto w-20 bg-white'
              />
            </div>
            <div
              className={cn(
                'absolute -bottom-1 -left-1 flex size-5 sm:size-7 items-center justify-center rounded-full border-2 border-white text-sm transition-colors',
                {
                  ['bg-red text-white animate-bounce-short']: unread,
                  ['bg-secondary text-secondary-fg']: !unread,
                },
              )}
            >
              {unread || (
                <MessageCircle
                  className='size-2.5 rotate-90 -scale-100 sm:size-3'
                  strokeWidth={2.5}
                />
              )}
            </div>
          </button>
        </motion.div>
      )}
    </AnimatePresence>
  );
});
