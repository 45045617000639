import { ThreadMode } from '@kanbu/schema/enums';
import { Trans } from '@lingui/react/macro';
import { Button } from '@utima/ui';
import { Sparkles, UserRound } from 'lucide-react';
import { memo } from 'react';

export interface ThreadModeIndicatorProps {
  mode: ThreadMode | undefined;
  onSwitchToAI?: () => void;
}

export const ThreadModeIndicator = memo(
  ({ mode, onSwitchToAI }: ThreadModeIndicatorProps) => {
    if (!mode || mode === ThreadMode.AI) {
      return null;
    }

    return (
      <div className='sticky top-0 z-10 flex items-center justify-between gap-2 bg-background-secondary px-5 py-2 text-sm'>
        <div className='flex items-center'>
          <div className='mr-1 size-2 animate-pulse rounded-full bg-green-500' />
          <UserRound className='size-4 text-green-500' />
          <span className='ml-2'>
            <Trans>Talking to human operator</Trans>
          </span>
        </div>
        <Button variant='ghost' outline size='xs' onClick={onSwitchToAI}>
          <Sparkles className='size-3' />
          <Trans>Switch to AI</Trans>
        </Button>
      </div>
    );
  },
);
