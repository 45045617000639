import { parse, Allow } from 'partial-json';

import type { StreamAdapterEvent, IStreamSerializer } from './streamTypes';

/**
 * Maps event types to a single character. This is used to reduce the
 * amount of data sent over the wire.
 */
const TypeToKeyEventMap: Record<StreamAdapterEvent['type'], string> = {
  init: 'a',
  'text-chunk': 'b',
  'text-start': 'c',
  'text-end': 'd',
  'tool-call-start': 'e',
  'tool-call-chunk': 'f',
  'tool-call-end': 'g',
  interrupt: 'h',
  error: 'i',
  end: 'j',
  'tool-result': 'o',
} as const;

const KeyToEventTypeMap: Record<string, StreamAdapterEvent['type']> =
  Object.fromEntries(
    Object.entries(TypeToKeyEventMap).map(([key, value]) => [value, key]),
  ) as Record<string, StreamAdapterEvent['type']>;

/**
 * Serializes and deserializes stream events. We use a custom format to
 * reduce the amount of data sent over the wire.
 *
 * Essentially the type of event is serialized to a single character and
 * then the event is serialized to a string. Both are separated by column.
 *
 * For tools we also include the index of the tool call to make it easier
 * to match chunks together.
 */
export class StreamSerializer implements IStreamSerializer {
  /**
   * Serializes a stream event to a string.
   */
  serialize(event: StreamAdapterEvent): string {
    try {
      const { type, ...rest } = event;
      const prefix = TypeToKeyEventMap[type];

      switch (type) {
        case 'init':
        case 'end':
        case 'error':
        case 'interrupt':
        case 'text-start':
        case 'text-end':
        case 'tool-call-start':
        case 'tool-call-end':
        case 'tool-result':
          return `${prefix}:${JSON.stringify(rest)}\n`;

        // Text events have content as a string directly after the prefix
        case 'text-chunk':
          return `${prefix}:${encodeURIComponent(event.content)}\n`;

        // Tool calls include index as additional column identifier
        case 'tool-call-chunk':
          return `${prefix}:${event.index}:${encodeURIComponent(event.args)}\n`;

        default:
          throw new Error(`Unknown event type: ${type}`);
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  /**
   * Deserializes a stream event from a string.
   */
  deserialize(line: string): StreamAdapterEvent {
    try {
      const [type, ...rest] = line.split(':');
      const eventType = KeyToEventTypeMap[type];

      switch (eventType) {
        case 'init':
        case 'end':
        case 'error':
        case 'interrupt':
        case 'text-start':
        case 'text-end':
        case 'tool-call-start':
        case 'tool-call-end':
          return { type: eventType, ...JSON.parse(rest.join(':')) };

        case 'tool-result':
          /**
           * Since some tools can be too long and cutof, we use partial-json
           * to serialize the event.
           */
          return {
            type: eventType,
            ...parse(rest.join(':'), Allow.ALL),
          };

        // Text events have content as a string directly after the prefix
        case 'text-chunk':
          return {
            type: eventType,
            content: decodeURIComponent(rest.join(':')),
          };

        // Tool calls include index as additional column identifier
        case 'tool-call-chunk': {
          const [index, ...argParts] = rest;

          return {
            type: eventType,
            index: Number.parseInt(index, 10),
            args: decodeURIComponent(argParts.join(':')),
          };
        }

        default:
          throw new Error(`Unknown event type: ${type}`);
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}
