import { z } from 'zod';

import { baseEntitySchema } from './baseContract';

export const tierConfigSchema = z.object({
  usersLimit: z.number().min(1),
  voiceBot: z.boolean().optional(),
  disableAiInboxUnread: z.boolean().optional(),
  disableAllInboxUnread: z.boolean().optional(),
});

export const workingHoursDaySchema = z.union([
  z.boolean(), // Closed
  z.array(
    z.object({
      from: z.string().datetime(),
      to: z.string().datetime(),
    }),
  ),
]);

// z.record is not working correctly with EntityDTO type
export const workingHoursSchema = z
  .object({
    '0': workingHoursDaySchema,
    '1': workingHoursDaySchema,
    '2': workingHoursDaySchema,
    '3': workingHoursDaySchema,
    '4': workingHoursDaySchema,
    '5': workingHoursDaySchema,
    '6': workingHoursDaySchema,
  })
  .partial()
  .nullish();

export const organizationSchema = baseEntitySchema.extend({
  id: z.string().uuid(),
  name: z.string().max(150),
  url: z.string().url().max(150).optional(),
  monthlyUsageLimit: z.number().min(0).max(999_999_999.99).optional(),
  tierConfig: tierConfigSchema,
  workingHours: workingHoursSchema,
});

// Base organization schema has to be defined here because of cyclic imports
export const baseOrganizationEntitySchema = baseEntitySchema.extend({
  organization: organizationSchema,
});

// Create DTO schema
export const createOrganizationSchema = organizationSchema
  .omit({
    id: true,
    createdAt: true,
    updatedAt: true,
  })
  .strict();

// Update DTO schema
export const updateOrganizationSchema = organizationSchema
  .partial()
  .required({
    id: true,
  })
  .strict();

export type OrganizationDTO = z.infer<typeof organizationSchema>;
export type CreateOrganizationDTO = z.infer<typeof createOrganizationSchema>;
export type UpdateOrganizationDTO = z.infer<typeof updateOrganizationSchema>;
export type OrganizationTierConfig = z.infer<typeof tierConfigSchema>;
export type OrganizationWorkingHours = z.infer<typeof workingHoursSchema>;
export type OrganizationWorkingHoursDay = z.infer<typeof workingHoursDaySchema>;
