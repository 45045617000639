import { Trans, useLingui } from '@lingui/react/macro';

import { useBoundStore } from '@/store/store';

import { Stack } from '../stack/Stack';

export function Hint() {
  const { t } = useLingui();
  const { pushRoute } = useBoundStore(state => ({
    pushRoute: state.pushRoute,
  }));

  return (
    <Stack title={t`Hint`} onBack={() => pushRoute('chat')}>
      {/* FIXME: When translations on backend are ready*/}
      <ul className='list-disc space-y-2 px-4'>
        <li>
          <Trans>
            For the best and most relevant answers, write questions{' '}
            <span className='font-bold'>
              clearly and in complete sentences.
            </span>
          </Trans>
        </li>
        <li>
          <Trans>
            If the answer is not accurate enough,{' '}
            <span className='font-bold'>
              clarify the question or add all important information{' '}
            </span>
            (related year, place, categorization, etc.).
          </Trans>
        </li>
        <li>
          <Trans>
            If I keep answering incorrectly even after clicking &quot;Start new
            conversation&quot; or clarifying the question, I will{' '}
            <span className='font-bold'>redirect you to a human operator.</span>
          </Trans>
        </li>
        <li>
          <Trans>
            I only answer questions related to our products and services. I am
            not intended to answer questions beyond the scope of the content we
            have specified.
          </Trans>
        </li>
        <li>
          <Trans>
            I <span className='font-bold'>only understand text messages.</span>{' '}
            You cannot upload images, voice messages, or ask me to tell you
            about the content of images from PDF files.
          </Trans>
        </li>
      </ul>
    </Stack>
  );
}
