import { Image } from '@kanbu/shared-ui';
import { Trans } from '@lingui/react/macro';
import { CircleHelp, Minus, MessagesSquare } from 'lucide-react';
import { memo } from 'react';

import headerDark from '@/assets/images/header-dark.png';
import headerKanbu from '@/assets/images/header-kanbu.png';
import headerLight from '@/assets/images/header-light.png';
import { useChatConfig } from '@/contexts/ChatConfigProvider';
import { useBoundStore } from '@/store/store';

import { NewThread } from './NewThread';
import { TopBarButton } from './TopBarButton';
import { SimpleTooltip } from '../tooltip/SimpleTooltip';

export const TopBar = memo(function TopBar() {
  const { themeConfig } = useChatConfig();
  const themePreset = themeConfig?.preset;
  const headerImage =
    themePreset === 'kanbu'
      ? headerKanbu
      : themePreset === 'light'
        ? headerLight
        : headerDark;
  const [close, setUnread] = useBoundStore(state => [
    state.close,
    state.setUnread,
  ]);
  const { pushRoute } = useBoundStore(state => ({
    pushRoute: state.pushRoute,
  }));
  /**
   * Close and clear unread messages.
   */
  const handleClose = () => {
    setUnread(0);
    close();
  };

  const handleThreads = () => {
    pushRoute('threads');
  };

  const handleSettings = () => {
    pushRoute('settings');
  };

  return (
    <div className='sticky top-0 flex h-14 flex-row items-center justify-between bg-primary px-4'>
      <div className='flex items-center gap-3'>
        <Image className='h-5' src={themeConfig?.logo ?? headerImage} />
      </div>
      <div className='flex gap-4'>
        <SimpleTooltip title={<Trans>Help</Trans>} asChild>
          <TopBarButton onClick={handleSettings}>
            <CircleHelp />
          </TopBarButton>
        </SimpleTooltip>
        <SimpleTooltip title={<Trans>Previous conversations</Trans>} asChild>
          <TopBarButton onClick={handleThreads}>
            <MessagesSquare />
          </TopBarButton>
        </SimpleTooltip>
        <NewThread />
        <SimpleTooltip title={<Trans>Minimize</Trans>} asChild>
          <TopBarButton onClick={handleClose}>
            <Minus />
          </TopBarButton>
        </SimpleTooltip>
      </div>
    </div>
  );
});
