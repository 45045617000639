export function getPortalContainer() {
  // Production uses shadow DOM so we need to get the container from the shadow root
  const shadowRoot = document.querySelector('kanbu-chatbot')?.shadowRoot;

  if (shadowRoot) {
    return shadowRoot.getElementById('kanbu-chatbot-app__container');
  }

  // Development uses the default container
  return document.getElementById('kanbu-chatbot-app__container');
}
