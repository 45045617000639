import type { ThreadMode } from '@kanbu/schema/enums';
import { ONLINE_STATUS_THRESHOLD } from '@kanbu/shared';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { useChatConfig } from '@/contexts/ChatConfigProvider';
import { aiCoreApi } from '@/services/aiCoreClient';
import { threadKeys } from '@/services/queryClient';

/**
 * Pings the API each X seconds to keep track of user activity and current
 * conversation mode.
 *
 * Additionally it provides callbacks to handle admin takeover.
 */
export function useActivityPing(
  threadId: string | undefined | null,
  interval = ONLINE_STATUS_THRESHOLD * 0.8,
) {
  const { chatId } = useChatConfig();
  const queryClient = useQueryClient();

  /**
   * Ping the thread to keep track of user activity and current
   * conversation mode.
   */
  const { data: pingData, refetch } = useQuery({
    queryKey: [...threadKeys.detail(threadId!)],
    queryFn: async () =>
      aiCoreApi.threads.ping({
        threadId: threadId!,
        chatId,
      }),
    refetchInterval: interval,
    enabled: !!threadId,
  });

  /**
   * Pings the thread to switch to a specific mode.
   */
  const ping = useMutation({
    mutationFn: async (mode: ThreadMode) =>
      aiCoreApi.threads.ping({
        threadId: threadId!,
        chatId,
        mode,
      }),
    onSuccess: () => {
      // Invalidate threads to refetch the latest data
      queryClient.invalidateQueries({
        queryKey: threadKeys.chatLists(chatId),
      });
    },
  });

  /**
   * Accept the takeover request.
   */
  const handleAcceptTakeover = useCallback(async () => {
    if (!threadId) {
      return;
    }

    await aiCoreApi.threads.respond({ threadId, chatId, accepted: true });
    await refetch();

    // Invalidate threads to refetch the latest data
    queryClient.invalidateQueries({
      queryKey: threadKeys.chatLists(chatId),
    });
  }, [threadId, chatId, refetch, queryClient]);

  /**
   * Deny the takeover request.
   */
  const handleDenyTakeover = useCallback(async () => {
    if (!threadId) {
      return;
    }

    await aiCoreApi.threads.respond({ threadId, chatId, accepted: false });
    await refetch();

    // Invalidate threads to refetch the latest data
    queryClient.invalidateQueries({
      queryKey: threadKeys.chatLists(chatId),
    });
  }, [threadId, chatId, refetch, queryClient]);

  return {
    ping,
    pingData,
    acceptTakeover: handleAcceptTakeover,
    denyTakeover: handleDenyTakeover,
  };
}
