import type { Config } from '@kanbu/config';

import { version } from '../../package.json';

const envConfig =
  __KANBU_CHATBOT_CONFIG__ as any as Config['app']['chatbotApp'] & {
    debug: boolean;
  };

export const AppSettings = {
  ...envConfig,
  debug: document.cookie.includes('debug=true') || envConfig.debug,
  version,
};
