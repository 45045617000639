import type { TakeoverRequestDTO } from '@kanbu/schema/contracts';
import { formatUtils } from '@kanbu/shared';
import { useTimeAgo, UserAvatar } from '@kanbu/shared-ui';
import { Trans } from '@lingui/react/macro';
import { Button, cn } from '@utima/ui';
import { motion } from 'framer-motion';
import { useCallback, useState } from 'react';

export interface TakeoverNotifyProps {
  className?: string;
  takeover: TakeoverRequestDTO;
  onAccept: () => Promise<void>;
  onDeny: () => Promise<void>;
}

// Animation variants for staggered children animations
const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      staggerChildren: 0.05,
      staggerDirection: -1,
    },
  },
};

const itemVariants = {
  hidden: { opacity: 0, y: -10 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: 'spring',
      stiffness: 500,
      damping: 24,
    },
  },
  exit: {
    opacity: 0,
    y: -10,
    transition: {
      duration: 0.2,
    },
  },
};

export function TakeoverNotify({
  takeover,
  onAccept,
  onDeny,
  className,
}: TakeoverNotifyProps) {
  const timeAgo = useTimeAgo(takeover.createdAt);
  const [isDenying, setIsDenying] = useState(false);
  const [isAccepting, setIsAccepting] = useState(false);

  const handleAccept = useCallback(async () => {
    setIsAccepting(true);

    try {
      await onAccept();
    } finally {
      setIsAccepting(false);
    }
  }, [onAccept]);

  const handleDeny = useCallback(async () => {
    setIsDenying(true);

    try {
      await onDeny();
    } finally {
      setIsDenying(false);
    }
  }, [onDeny]);

  const fullName = formatUtils.fullName(takeover.user);

  return (
    <motion.div
      key='takeover-container'
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className='absolute left-0 top-14 z-50 w-full p-5'
    >
      <motion.div
        key='takeover-notification'
        initial={{ opacity: 0, y: -20, scale: 0.95 }}
        animate={{ opacity: 1, y: 0, scale: 1 }}
        exit={{
          opacity: 0,
          y: -30,
          scale: 0.85,
          transition: {
            duration: 0.3,
            ease: [0.43, 0.13, 0.23, 0.96], // Custom easing for a more dramatic effect
          },
        }}
        transition={{
          type: 'spring',
          stiffness: 400,
          damping: 25,
          duration: 0.3,
        }}
        className={cn(
          'bg-background flex w-full gap-2 flex-col rounded-lg border border-border p-3 text-sm shadow-sm drop-shadow-xl',
          className,
        )}
      >
        <motion.div
          variants={containerVariants}
          initial='hidden'
          animate='visible'
          exit='exit'
          className='flex flex-col gap-2'
        >
          <motion.div
            variants={itemVariants}
            className='flex items-center gap-2'
          >
            <UserAvatar fallback={formatUtils.initials(fullName)} />
            <div className='min-w-0 grow items-center'>
              <h3 className='truncate font-semibold'>{fullName}</h3>
              <p className='text-[11px] leading-[14px] text-placeholder'>
                {timeAgo}
              </p>
            </div>
          </motion.div>

          <motion.div variants={itemVariants} className='text-sm'>
            {takeover.message}
          </motion.div>

          <motion.div
            variants={itemVariants}
            className='flex justify-end gap-2'
          >
            <Button
              variant='primary'
              size='xs'
              className='h-6 px-2 py-0 text-xs'
              onClick={handleAccept}
              disabled={isAccepting || isDenying}
              loading={isAccepting}
            >
              <Trans>Accept</Trans>
            </Button>

            <Button
              variant='ghost'
              size='xs'
              className='h-6 px-2 py-0 text-xs'
              onClick={handleDeny}
              disabled={isAccepting || isDenying}
              loading={isDenying}
            >
              <Trans>Deny</Trans>
            </Button>
          </motion.div>
        </motion.div>
      </motion.div>
    </motion.div>
  );
}
