import { useLingui } from '@lingui/react/macro';
import { useMutation } from '@tanstack/react-query';
import { FormTranslationsContext } from '@utima/ui-informed';
import { memo, useCallback, useEffect, useRef, useMemo } from 'react';

import bubbleTheme from '@/assets/images/avatar.webp';
import bubbleKanbu from '@/assets/images/bubble-kanbu.webp';
import { useBoundStore } from '@/store/store';

import { Bubble } from './components/bubble/Bubble';
import { Chat } from './components/chat/Chat';
import { ChatWindow } from './components/chat/ChatWindow';
import { Hint } from './components/hint/Hint';
import { Route } from './components/route/Route';
import { Threads } from './components/threads/Threads';
import { Welcome } from './components/welcome/Welcome';
import { useChatConfig } from './contexts/ChatConfigProvider';
import { useUser } from './hooks/useUser';
import { aiCoreApi } from './services/aiCoreClient';

export const Widget = memo(function Widget() {
  const { t } = useLingui();
  const { isLoggedIn } = useUser();
  const { chatId, themeConfig } = useChatConfig();
  const bubbleImg = themeConfig?.preset === 'kanbu' ? bubbleKanbu : bubbleTheme;
  const { open, opened, setUnread, logout } = useBoundStore(state => ({
    open: state.open,
    opened: state.opened,
    setUnread: state.setUnread,
    logout: state.logout,
  }));

  const formTranslations = useMemo(
    () => ({
      labels: {
        optional: t`Optional field`,
      },
      errors: {
        required: t`Required field`,
      },
    }),
    [t],
  );

  const handleOpen = useCallback(() => {
    open();
    setUnread(0);
  }, [open, setUnread]);

  /**
   * Verify the chat user. This will verify that the user id
   * still exists in the database. If not we will "logout" the user
   * to ensure that he has to be logged in again.
   */
  const { mutateAsync: verify } = useMutation({
    mutationFn: async (chatId: string) => {
      const result = await aiCoreApi.auth.verify(
        { chatId },
        {
          retry: 0,
        },
      );

      return result.valid;
    },
    onSuccess: (valid: boolean) => {
      /**
       * Logout user if verification failed.
       */
      if (!valid) {
        logout();
      }
    },
  });

  // Use useRef to track if verification was already attempted
  const verificationAttempted = useRef(false);

  // We want to verify the chat user when the widget is opened first time
  useEffect(() => {
    // Verify only if widget is open
    if (!opened) {
      return;
    }

    if (!isLoggedIn || verificationAttempted.current) {
      return;
    }

    verificationAttempted.current = true;
    verify(chatId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opened]);

  return (
    <FormTranslationsContext.Provider value={formTranslations}>
      <ChatWindow>
        <Route name='welcome'>
          <Welcome />
        </Route>
        <Route name='chat'>
          <Chat />
        </Route>
        <Route name='threads'>
          <Threads />
        </Route>
        <Route name='settings'>
          <Hint />
        </Route>
      </ChatWindow>

      <Bubble src={bubbleImg} onClick={handleOpen} />
    </FormTranslationsContext.Provider>
  );
});
