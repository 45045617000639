import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useRef } from 'react';
import { v7 as uuidV7 } from 'uuid';

import { aiCoreApi } from '@/services/aiCoreClient';
import { threadKeys } from '@/services/queryClient';

export type UseChatThreadsParams = {
  chatId: string;
  threadId: string | null;
  setThreadId: (threadId: string) => void;
  opened: boolean;
};

/**
 * Custom hook to handle threads, sending and receiving messages,
 * and managing chat history.
 */
export function useChatThreads({
  chatId,
  threadId,
  setThreadId,
  opened,
}: UseChatThreadsParams) {
  const threadRefreshTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const queryClient = useQueryClient();

  /**
   * Fetch user threads to display in the chat list.
   */
  const { data: threads } = useQuery({
    queryKey: threadKeys.chatLists(chatId),
    staleTime: Number.POSITIVE_INFINITY,
    queryFn: async () => {
      try {
        const data = await aiCoreApi.threads.findAll(
          {
            chatId,
          },
          /**
           * We don't want to throw an error if the thread is not found
           * This will allow us to create a new thread.
           */
          {
            throwHttpErrors: false,
            retry: 0,
          },
        );

        // Set thread id to the first thread in the list
        if (!threadId) {
          setThreadId(data[0].id);
        }

        return data;
      } catch {
        // Initialize new thread
        setThreadId(uuidV7());

        return [];
      }
    },
    enabled: opened,
  });

  /**
   * Invalidate thread queries after receiving a message to refresh the thread list
   */
  const handleThreadRefresh = useCallback(() => {
    // Clear any existing timeout
    if (threadRefreshTimeoutRef.current) {
      clearTimeout(threadRefreshTimeoutRef.current);
    }

    // Set new timeout to invalidate queries
    threadRefreshTimeoutRef.current = setTimeout(() => {
      queryClient.invalidateQueries({
        queryKey: threadKeys.chatLists(chatId),
      });
      threadRefreshTimeoutRef.current = null;
    }, 5000);
  }, [chatId, queryClient]);

  // Cleanup timeout on unmount
  useEffect(() => {
    return () => {
      if (threadRefreshTimeoutRef.current) {
        clearTimeout(threadRefreshTimeoutRef.current);
        threadRefreshTimeoutRef.current = null;
      }
    };
  }, []);

  return {
    handleThreadRefresh,
    threads,
    activeThread: threads?.find(t => t.id === threadId) ?? null,
  };
}
