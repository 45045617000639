import { DateFormat, formatUtils } from '@kanbu/shared';
import { Trans } from '@lingui/react/macro';
import { useQuery } from '@tanstack/react-query';
import { ChevronRight, Loader2 } from 'lucide-react';

import { useChatConfig } from '@/contexts/ChatConfigProvider';
import { aiCoreApi } from '@/services/aiCoreClient';
import { threadKeys } from '@/services/queryClient';
import { useBoundStore } from '@/store/store';

export function Threads() {
  const { chatId } = useChatConfig();
  const { setThreadId, pushRoute } = useBoundStore(state => ({
    setThreadId: state.setThreadId,
    pushRoute: state.pushRoute,
  }));
  const { data: threads, isLoading } = useQuery({
    queryKey: threadKeys.chatLists(chatId),
    staleTime: 30 * 60 * 1000,
    refetchOnWindowFocus: true,
    queryFn: () => {
      return aiCoreApi.threads.findAll({
        chatId,
      });
    },
  });

  if (isLoading) {
    return (
      <div className='relative flex size-full items-center justify-center'>
        <Loader2 className='size-10 animate-spin' />
      </div>
    );
  }

  return (
    <div className='mx-auto size-full max-w-2xl overflow-y-auto p-4'>
      {threads && threads.length > 0 ? (
        <ul className='space-y-2'>
          {threads.map(thread => {
            return (
              <li key={thread.id}>
                <button
                  className='flex w-full items-center justify-between rounded-lg border border-background-secondary px-4 py-3 transition-colors hover:bg-background-secondary'
                  type='button'
                  onClick={() => {
                    setThreadId(thread.id);
                    pushRoute('chat');
                  }}
                >
                  <div className='min-w-0 flex-1 text-left'>
                    <h3 className='truncate font-medium'>
                      {thread.title || <Trans>(Untitled conversation)</Trans>}
                    </h3>
                    <p className='truncate text-sm'>
                      {formatUtils.date(
                        thread.createdAt,
                        DateFormat.DateTimeNumeric,
                      )}
                    </p>
                  </div>

                  <ChevronRight className='text-placeholder' />
                </button>
              </li>
            );
          })}
        </ul>
      ) : (
        <div className='flex h-full items-center justify-center'>
          <Trans>No threads found</Trans>
        </div>
      )}
    </div>
  );
}
