import { ChatRole } from '@kanbu/schema/enums';
import {
  type ChatSocketServerEvents,
  type ChatSocketClientEvents,
  type Nullish,
  SocketError,
} from '@kanbu/shared';
import type { ChatMessageItem } from '@kanbu/shared-ui';
import { useLingui } from '@lingui/react/macro';
import { useEffect, useCallback, useState } from 'react';
import { type Socket, io } from 'socket.io-client';

import { AppSettings } from '@/constants/AppSettings';
import { useUser } from '@/hooks/useUser';

import type { UseChatParams, UseChatReturn } from './chatTypes';

const SOCKET_URL = `${new URL(AppSettings.apiUrl).origin}/chat`;

// Create socket instance, there should be only one per app instance
const socket: Socket<ChatSocketServerEvents, ChatSocketClientEvents> = io(
  SOCKET_URL,
  {
    transports: ['websocket'],
    reconnection: true,
    reconnectionAttempts: 3,
    timeout: 10000,
    autoConnect: false,
  },
);

export interface UseChatSocketParams {
  enabled: boolean;
  onMessage?: UseChatParams['onMessage'];
  onError?: UseChatParams['onError'];
  insert: UseChatReturn['insert'];
  threadId: Nullish<string>;
}

/**
 * This should be used within the useChat hook. It enables
 * live chat functionality between the chatbot and the operator.
 *
 * The hook itself should not be used directly outside of the useChat hook.
 * While the APIs are somehow usable, it doesn't handle state persistence
 * and other logic, since these are handled by the useChat hook. It only
 * handles the socket connection and correct forwarding of events to
 * the useChat hook.
 */
export function useChatSocket({
  enabled,
  threadId,
  onError,
  onMessage,
  insert,
}: UseChatSocketParams) {
  const { t } = useLingui();
  const [isConnected, setIsConnected] = useState(false);
  const { accessToken } = useUser();

  /**
   * Send message to the websocket
   */
  const sendMessage = useCallback(
    (message: ChatMessageItem) => {
      if (!threadId) {
        return;
      }

      if (!socket.connected) {
        return;
      }

      socket.emit('message', message);
    },
    [threadId],
  );

  /**
   * Initialize socket connection when enabled and
   * threadId is provided
   */
  useEffect(() => {
    if (enabled && accessToken) {
      socket.auth = { token: accessToken };
      socket.connect();
      setIsConnected(true);
    }

    return () => {
      socket.connected && socket.disconnect();
      setIsConnected(false);
    };
  }, [enabled, accessToken]);

  /**
   * Handle re-joining the thread when the threadId changes.
   */
  useEffect(() => {
    if (!isConnected) {
      return;
    }

    // Leave previous thread
    socket.emit('leaveThread');

    // Join new thread
    if (threadId) {
      socket.emit('joinThread', threadId);
    }

    // Cleanup on unmount
    return () => {
      socket.emit('leaveThread');
    };
  }, [threadId, isConnected]);

  /**
   * Handle switching between AI and Human mode. If we're in human mode,
   * we need to join the thread websocket and apply proper listeners.
   * Otherwise we need to disconnect gracefully.
   */
  useEffect(() => {
    if (!enabled || !isConnected) {
      return;
    }

    const handleConnect = () => {
      insert(
        t`AI has been disconnected, please wait, we're currently looking for free operators...`,
        ChatRole.Status,
      );
    };

    const handleConnectError = (error: unknown) => {
      const socketError = SocketError.fromError(error);

      if (socketError.type === 'unauthorized') {
        onError?.(
          t`We were unable to authenticate your request. Please try again later.`,
          insert,
        );
      } else {
        onError?.(
          t`We're sorry, but we're currently experiencing issues with our operators. Please try again later.`,
          insert,
        );
      }

      console.error('Socket connection error:', error);
    };

    const handleMessage = (message: ChatMessageItem) => {
      insert(message);
      onMessage?.(message, insert);
    };

    /**
     * Initialize socket.io connection & events
     */
    socket.on('connect', handleConnect);
    socket.on('connect_error', handleConnectError);
    socket.on('message', handleMessage);

    /**
     * Cleanup on unmount
     */
    return () => {
      socket.off('connect', handleConnect);
      socket.off('connect_error', handleConnectError);
      socket.off('message', handleMessage);
    };
  }, [onMessage, onError, insert, t, enabled, isConnected]);

  return { socket, sendMessage, isConnected };
}
