import { cn } from '@utima/ui';

type CharacterCounterProps = {
  max: number;
  current: number;
};

export function CharacterCounter({ max, current }: CharacterCounterProps) {
  return (
    <span
      className={cn(
        'text-xs w-14 text-right',
        current >= max ? 'text-red' : 'text-gray',
      )}
    >
      {current}/{max}
    </span>
  );
}
