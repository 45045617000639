import { cn, Slot } from '@utima/ui';
import { forwardRef, type ButtonHTMLAttributes } from 'react';

export interface TopBarButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  asChild?: boolean;
}

export const TopBarButton = forwardRef<HTMLButtonElement, TopBarButtonProps>(
  ({ className, asChild, ...restProps }: TopBarButtonProps, ref) => {
    const Comp = asChild ? Slot : 'button';

    return (
      <Comp
        type='button'
        ref={ref}
        className={cn(
          'flex items-center justify-center text-primary-fg transition-transform hover:scale-110 active:scale-100 [&>svg]:size-6 [&_svg]:stroke-[1.5]',
          className,
        )}
        {...restProps}
      />
    );
  },
);
