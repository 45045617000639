import * as Tooltip from '@radix-ui/react-tooltip';
import { cn } from '@utima/ui';
import {
  useEffect,
  useState,
  type ComponentPropsWithoutRef,
  type ReactNode,
} from 'react';

import { getPortalContainer } from '@/lib/utils';

export interface SimpleTooltipProps
  extends Omit<ComponentPropsWithoutRef<typeof Tooltip.Root>, 'title'> {
  title: ReactNode;
  asChild?: boolean;
  className?: string;
  delayDuration?: number;
}

export function SimpleTooltip({
  title,
  children,
  delayDuration = 200,
  asChild,
  className,
  ...restProps
}: SimpleTooltipProps) {
  const [portalRef, setPortalRef] = useState<HTMLElement | null>(null);

  useEffect(() => {
    setPortalRef(getPortalContainer());
  }, []);

  if (!portalRef) {
    return null;
  }

  return (
    <Tooltip.Provider delayDuration={delayDuration}>
      <Tooltip.Root {...restProps}>
        <Tooltip.Trigger asChild={asChild}>{children}</Tooltip.Trigger>
        <Tooltip.Portal container={portalRef}>
          <Tooltip.Content
            className={cn(
              'z-50 w-fit rounded-md bg-background px-3 py-1.5 text-sm shadow-md shadow-black/15 drop-shadow-sm animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
              className,
            )}
          >
            {title}
            <Tooltip.Arrow className='z-50 size-2.5 translate-y-[calc(-50%_-_2px)] rotate-45 rounded-sm bg-background fill-background' />
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
}
