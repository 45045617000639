import { ChatRole } from '@kanbu/schema/enums';
import { sortMessagesChronologically } from '@kanbu/shared';
import { createMessage, type ChatMessageItem } from '@kanbu/shared-ui';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { HTTPError } from '@toss/ky';
import {
  useCallback,
  useMemo,
  useRef,
  useState,
  useTransition,
  type ChangeEvent,
  type FormEvent,
} from 'react';

import { processChatStream } from '@/lib/streamingUtils';
import { aiCoreApi } from '@/services/aiCoreClient';
import { chatKeys } from '@/services/queryClient';

import type {
  InsertOptions,
  UseChatParams,
  UseChatReturn,
} from './chatTypes.js';
import { useChatSocket } from './useChatSocket.js';

const INITIAL_MESSAGES: ChatMessageItem[] = [];

/**
 * Custom hook to handle chat logic, sending and receiving messages,
 * and managing chat history.
 *
 * Internally it uses useChatSocket to enable live chat functionality.
 * When `activateLiveChat` is true, all messages are forwared to the
 * websocket and handled by the useChatSocket hook, instead of the
 * default AI completion functionality.
 */
export function useChat({
  initialInput = '',
  initialMessages = INITIAL_MESSAGES,
  activateLiveChat = false,
  threadId,
  chatId,
  onMessage,
  onError,
}: UseChatParams): UseChatReturn {
  const [_, startTransition] = useTransition();
  const [input, setInput] = useState(initialInput);
  const [error, setError] = useState<unknown>(null);
  const [isFetching, setIsFetching] = useState(false);
  const abortController = useRef<null | AbortController>(null);

  const queryClient = useQueryClient();
  const queryKey = useMemo(
    () => [...chatKeys.detail(chatId), threadId],
    [chatId, threadId],
  );

  /**
   * Fetch message history, we also use this to store the chat
   * history in the state. This automatically handles cache invalidation
   * when thread is changed.
   */
  const { data: messages, isLoading: isLoadingMessages } = useQuery<
    ChatMessageItem[]
  >({
    queryKey: queryKey,
    queryFn: async () => {
      try {
        const data = await aiCoreApi.threads.findOne(
          {
            chatId,
            id: threadId!,
          },
          {
            /**
             * We don't want to throw an error if the thread is not found,
             * since it can be created automatically on the fly.
             */
            throwHttpErrors: false,
            retry: 0,
          },
        );

        /**
         * Since we use uuid v7 which is time based, we can sort the messages
         * by id to get the correct order.
         */
        return data.messages.length > 0
          ? data.messages
              .slice()
              .sort(sortMessagesChronologically)
              .map(m => ({
                ...m,
                // Add isInitial flag
                isInitial: true,
              }))
          : initialMessages;
      } catch {
        return initialMessages;
      }
    },
    placeholderData: INITIAL_MESSAGES,
    // We want to keep the messages in the cache forever
    staleTime: Number.POSITIVE_INFINITY,
    // We want to make sure the messages are fresh on mount and window focus
    refetchOnMount: 'always',
    refetchOnWindowFocus: 'always',
    enabled: !!threadId,
  });

  /**
   * Abort the current request.
   */
  const abort = useCallback(() => {
    if (abortController.current) {
      abortController.current.abort();
    }
  }, []);

  /**
   * Helper for calling completion API with stream option.
   */
  const callCompletionApi = useCallback(
    async (message: ChatMessageItem | string) => {
      const content = typeof message === 'string' ? message : message.content;

      // Bail if no content
      if (!content) {
        return;
      }

      // Abort previous request
      if (
        abortController.current?.signal &&
        !abortController.current.signal.aborted
      ) {
        abortController.current.abort();
      }

      abortController.current = new AbortController();

      // Get the completion API endpoint stream
      const response = await aiCoreApi.chat.completion(
        {
          chatId,
          threadId: threadId!,
          message: content,
        },
        {
          raw: true,
          timeout: undefined,
          signal: abortController.current?.signal,
        },
      );

      return response.body?.getReader();
    },
    [chatId, abortController, threadId],
  );

  /**
   * Helper for handling AI completion. Processes chat stream
   * and handles streaming the response from API to the chat state.
   */
  const handleAiCompletion = useCallback(
    async (msg: ChatMessageItem | string) => {
      return processChatStream({
        abortController: () => abortController.current,
        onUpdate: (newMessage: ChatMessageItem) => {
          /**
           * We need to update the message in the state, if it already exists.
           * Otherwise we just append the new message.
           */
          queryClient.setQueryData(queryKey, (prev: ChatMessageItem[]) => {
            const initialMessageIndex = prev.findIndex(
              m => m.id === newMessage.id,
            );

            if (initialMessageIndex !== -1) {
              return [
                ...prev.slice(0, initialMessageIndex),
                { ...newMessage },
                ...prev.slice(initialMessageIndex + 1),
              ];
            }

            return [...prev, newMessage];
          });
        },
        reader: (await callCompletionApi(
          msg,
        )) as ReadableStreamDefaultReader<Uint8Array>,
      });
    },
    [callCompletionApi, queryClient, queryKey],
  );

  /**
   * Insert message into the chat state without sending it to the API.
   */
  const insert = useCallback(
    (
      message: ChatMessageItem | string,
      role: ChatRole = ChatRole.User,
      options?: InsertOptions,
    ) => {
      const { timeout = 0 } = options || {};

      (async () => {
        if (timeout > 0) {
          await new Promise(resolve => setTimeout(resolve, timeout));
        }

        queryClient.setQueryData(queryKey, (prev: ChatMessageItem[]) => {
          const newMessage = createMessage({
            message,
            role,
          });

          // First check if this message already exists (to avoid duplicates)
          if (prev && prev.some(m => m.id === newMessage.id)) {
            return prev;
          }

          return [...(prev ?? []), newMessage];
        });
      })();
    },
    [queryClient, queryKey],
  );

  /**
   * Initialize socket connection to support live chat.
   */
  const { sendMessage } = useChatSocket({
    enabled: activateLiveChat,
    threadId,
    onError,
    onMessage,
    insert,
  });

  /**
   * Create new message, add it to the state and send it to the API.
   */
  const append = useCallback(
    async (message: ChatMessageItem | string) => {
      if (!threadId) {
        return console.error('Thread ID is required to send a message.');
      }

      /**
       * Create a chat message object since we need ids, and timestamps
       * for other uses.
       */
      const chatMessage =
        typeof message === 'string'
          ? createMessage({
              message,
              role: ChatRole.User,
            })
          : message;

      startTransition(() => {
        insert(chatMessage);
        setIsFetching(true);
      });

      try {
        /**
         * When custom onSendMessage handler is provided, we use it
         * instead of the default AI completion. This is useful for
         * switching between AI and human mode using custom communication
         * channels.
         */
        if (activateLiveChat) {
          sendMessage({
            ...chatMessage,
            user: null,
          });
        } else {
          await handleAiCompletion(chatMessage);
        }
      } catch (error) {
        let parsedError = error;

        // Parse ky error response
        if (error instanceof HTTPError) {
          parsedError = await error.response.json();
        }

        setError(parsedError);
        onError?.(parsedError, insert);
      } finally {
        startTransition(() => {
          setIsFetching(false);
        });
      }
    },
    [
      threadId,
      insert,
      activateLiveChat,
      sendMessage,
      handleAiCompletion,
      onError,
    ],
  );

  /**
   * Input/textarea on change handler, automatically updates input value.
   */
  const handleInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
      setInput(e.target.value);
    },
    [],
  );

  /**
   * Handle form submission. Sends message to the API and resets input field.
   */
  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      startTransition(() => {
        append(input);
        setInput('');
      });
    },
    [append, input],
  );

  return {
    error,
    isLoading: !threadId || isLoadingMessages,
    isFetching,
    messages: messages ?? INITIAL_MESSAGES,
    input,
    setInput,
    handleSubmit,
    handleInputChange,
    abort,
    append,
    insert,
  };
}
