import { useQuery } from '@tanstack/react-query';
import { useRef } from 'react';

import type { UseChatReturn } from '@/components/chat/chatTypes';
import { useChatConfig } from '@/contexts/ChatConfigProvider';
import { aiCoreApi } from '@/services/aiCoreClient';
import { faqKeys } from '@/services/queryClient';
import { useBoundStore } from '@/store/store';

import { FAQItem } from './FAQItem';

export interface FaqsProps {
  insert: UseChatReturn['insert'];
}

export function FAQs({ insert }: FaqsProps) {
  const ref = useRef<HTMLDivElement>(null);
  const { chatId } = useChatConfig();
  const { currentFAQId, childrenCount } = useBoundStore();

  const { data } = useQuery({
    queryKey: [...faqKeys.chatLists(chatId), currentFAQId],
    queryFn: async () =>
      aiCoreApi.faqs.findAll({
        chatId,
        parentId: childrenCount && childrenCount > 0 ? currentFAQId : null,
      }),
    enabled: (!!childrenCount && childrenCount > 0) || !childrenCount,
  });

  return (
    <div
      className='flex flex-auto flex-col flex-wrap justify-end gap-2 pb-2 pt-6'
      ref={ref}
    >
      {data?.map(faq => (
        <div className='flex flex-row justify-end' key={faq.id}>
          <FAQItem
            title={faq.title}
            insert={insert}
            id={faq.id}
            question={faq.question}
            childrenCount={faq.childrenCount}
            answer={faq.answer}
          />
        </div>
      ))}
    </div>
  );
}
